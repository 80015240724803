<template>
  <page-main
    :title="`策略项目 - ${$route.query.id ? '编辑' : '新增'}策略`"
    back
    class="create"
  >
    <el-form
      ref="form"
      v-loading="loading"
      class="form"
      label-suffix="："
      :rules="formRules"
      :model="form"
    >
      <el-form-item label="策略名称" prop="strategyName">
        <el-input
          v-model="form.strategyName"
          :disabled="!!$route.query.id"
          style="width: 400px;"
          maxlength="40"
          show-word-limit
          placeholder="请输入策略描述"
        />
      </el-form-item>
      <el-form-item label="选择产品" prop="productTagId">
        <el-input
          v-model="filterText"
          :disabled="!!$route.query.id"
          placeholder="搜索产品关键词"
          prefix-icon="el-icon-search"
          style="width: 200px;"
          class="customize-el-input"
        />
        <div class="product-list">
          <el-radio-group
            v-model="form.productTagId"
            :disabled="!!$route.query.id"
            class="radio-group"
            size="mini"
            @input="onSelectProduct"
          >
            <el-radio
              v-for="item in productList"
              :key="item.id"
              :label="item.tagId"
            >
              <span
                v-highlight="{
                  hWord: filterText.replace(/ /g, '|'),
                  word: item.tagName,
                  style: highlightStyle,
                }"
              >{{ item.tagName }}</span>
            </el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="关键信息" prop="knowledgeIds">
        <el-table
          ref="multipleTable"
          v-loading="knowledgeLoading"
          max-height="300px"
          row-key="knowledgePointId"
          :data="knowledgeList"
          class="customize-el-table table"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            :selectable="(row) => !form.knowledgeIds.includes(row.knowledgePointId)"
            type="selection"
            :reserve-selection="true"
            width="55"
            align="center"
          />
          <el-table-column
            prop="knowledgePointName"
            show-overflow-tooltip
            align="center"
            label="产品"
          />
          <el-table-column
            prop="createAt"
            align="center"
            width="250"
            label="分类"
          >
            <template slot-scope="{row}">{{ oGetTypeVal(row) }}</template>
          </el-table-column>
        </el-table>
        <div class="description">
          <el-button
            type="text"
            style="text-decoration: underline;"
            @click="toCreateKnowledge"
          >
            点击此处
          </el-button>新建关键信息，<el-button
            style="text-decoration: underline;"
            type="text"
            @click="getKnowledgeList"
          >
            点击刷新
          </el-button>列表
        </div>
      </el-form-item>
    </el-form>
    <fixed-action-bar>
      <el-button
        plain
        style="min-width: 100px;"
        @click="$router.back()"
      >
        取消
      </el-button>
      <el-button
        style="min-width: 100px;"
        type="primary"
        @click="onSubmit"
      >
        保存
      </el-button>
    </fixed-action-bar>
  </page-main>
</template>

<script>
export default {
  name: 'ProductStategicCreate',
  data() {
    const validatorKnowledge = (rule, value, callback) => {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection?.length !== 0) {
        callback()
      } else {
        callback(new Error('请选择关键信息'))
      }
    }
    return {
      loading: false,
      knowledgeLoading: false,
      form: {
        strategyId: '',
        productTagId: '',
        productTagName: '',
        strategyName: '',
        status: 1,
        knowledgeIds: []
      },
      filterText: '',
      highlightStyle: 'background: transparent;color: #ff9941',
      formRules: {
        strategyName: [
          { required: true, message: '请输入策略名称', trigger: 'blur' }
        ],
        productTagId: [
          { required: true, message: '请选择产品', trigger: 'blur' }
        ],
        knowledgeIds: [
          {
            required: true,
            trigger: 'blur',
            validator: validatorKnowledge
          }
        ]
      },
      productList: [],
      tagGroup: [],
      knowledgeList: []
    }
  },
  computed: {
    groupRow() {
      return this.tagGroup.find(item => item.groupName.includes('产品'))
    }
  },
  mounted() {
    this.getDetail()
    this.getTagGroup().then(() => {
      this.getProdectList(this.groupRow.id)
    })
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate(async valid => {
        console.log('valid: ', valid)
        if (valid) {
          const params = {
            ...this.form
          }
          params.knowledgeIds = this.multipleSelection?.map(item => item.knowledgePointId)
          params.productTagName = this.productList.find(item => item.tagId === params.productTagId).tagName

          try {
            const { data } = await this.$axios.post(
              this.$API.addOrUpdateStrategy,
              params
            )
            if (data) {
              this.$msg.success('保存成功')
              this.$router.back()
            }
          } catch (error) {
            this.$msg.error(error?.message)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async getDetail() {
      try {
        if (!this.$route.query.id) return
        this.loading = true
        const params = {
          strategyId: this.$route.query.id
        }
        const { data } = await this.$axios.get(this.$API.getStrategyDetail, {params})
        data.knowledgeList?.map(item => {
          item.knowledgePointId = item.knowledgeId
          this.$refs.multipleTable.toggleRowSelection(item)
          return item
        }) || []
        data.knowledgeIds = data.knowledgeList?.map(item => item.knowledgePointId) || []

        this.form = data
        this.getKnowledgeList()
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    // 获取知识点信息
    async getKnowledgeList() {
      try {
        const params = {
          dataType: 1,
          dataId: this.form.productTagId
        }
        this.knowledgeLoading = true
        const { data } = await this.$axios.get(this.$API.getProductKnowledgePointList, {
          params
        })
        this.knowledgeList = data.knowledgePointList || []
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message || '')
      } finally {
        this.knowledgeLoading = false
      }
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onSelectProduct(productTagId) {
      this.form.productTagId = productTagId
      this.form.knowledgeIds = []
      this.$refs.multipleTable.clearSelection()
      this.getKnowledgeList()
    },
    toCreateKnowledge() {
      let routeUrl = {}
      if (this.form.productTagId) {
        routeUrl = this.$router.resolve({
          name: 'ProductLabelDetail',
          params: {
            id: this.form.productTagId
          },
          query: {
            groupId: this.groupRow.id
          }
        })
      } else {
        routeUrl = this.$router.resolve({
          name: 'ProductLabel'
        })
      }
      window.open(routeUrl.href, '_blank')
    },
    oGetTypeVal(row) {
      const obj = {
        1: '诊断',
        2: '品牌',
        3: '使用',
        4: '类别',
        5: '非诊疗'
      }
      return obj[String(row.pointType)] || '/'
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create {
  .form {
    .el-form-item {
      display: flex;
      .el-form-item__content {
        flex: 1;
        flex-shrink: 0;
      }
    }
    .product-list {
      width: 800px;
      min-height: 96px;
      max-height: 150px;
      border: 1px solid #e1e1e1;
      margin-top: 15px;
      padding: 10px 20px;
      overflow-y: auto;
      .radio-group {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        .el-radio {
          margin-right: 0;
          flex-shrink: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .table {
      width: 800px;
    }
    .description {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>
